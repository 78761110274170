<template>
  <div>
    <SearchBan />
    <div class="information">
      <h2>感谢报名第四届ASG杯</h2>
      <p>请点击<b>查询报名队伍</b>查询是否报名成功</p>
      <p style="color:orange;">橙色列表为直邀队伍</p>
      <p style="color:green;">绿色列表为报名成功队伍</p>
      <p style="color:red;">红色列表为报名失败队伍</p>
      <!-- 广告 -->
      <div class="advertise">
        <h2>招募广告</h2>
        <img src="https://www.kuaishou.com/profile/3xe9nk8mxcwin8k">
      </div>
    </div>
  </div>
</template>

<script>
import SearchBan from '../components/common/SearchBan.vue';
export default {
  name: '',
  components: { SearchBan }
}
</script>

<style scoped lang="css">
.information {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin:10px 0;
}

/* 广告区 */
.advertise {
  text-align: center;
}
.advertise img{
  width:100%;
}
</style>

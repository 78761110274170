<template>
    <div>
        <div class="container">
            <img src="https://i.hd-r.cn/d83dab6fd67e9a107f0fc27c3874ce7c.png">
            <router-link to="/">返回到主页</router-link>
        </div>
        <div class="allteam" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.6)">
            <h2>报名参赛列表如下:</h2>
            <el-table border :data="team" style="width: 100%" :cell-style="tableRowClassName">
                <el-table-column prop="name" label="战队名" min-width="100px" align="center">
                </el-table-column>
                <el-table-column prop="timer" label="报名时间" min-width="130px" align="center">
                </el-table-column>
                <el-table-column prop="piaoshu" label="票数" min-width="40px" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作" min-width="auto" center align="center">
                    <template slot-scope="scope">
                        <el-button @click="testGoogle(scope.row)" type="success" size="small">投票</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div id="googlebox">
            <GoogleRecaptcha v-if="showGoogle" @getToken="getToken" />
        </div>
    </div>
</template>

<script>
import GoogleRecaptcha from '../components/common/GoogleRecaptcha.vue';

export default {
    name: "Team",
    data() {
        return {
            team: [],
            loading: true,
            showGoogle: false,
            captoken: '',
            team_name: ''
        };
    },
    comments: { GoogleRecaptcha },
    mounted() {
        this.updateDOM();
        setTimeout(() => {
            this.loading = false;
        }, 2000);
    },
    methods: {
        testGoogle(row) {
            this.showGoogle = true,
                this.team_name = row.name
        },
        addlike(name) {
            this.axios.post(`/api/like?name=${name}&captoken=${this.captoken}`)
                .then(() => {
                    this.$message.success('为' + this.team_name + '战队投票成功！'),
                        this.updateDOM();
                })
                .catch((err) => {
                    this.$message.error('投票失败！' + err)
                })
        },
        getToken(token) {
            this.captoken = token,
                this.showGoogle = false,
                this.addlike(this.team_name)
        },
        /**
         * @Author Spring <793651012@qq.com>
         * @license asgWeb-1.0
         * @param {number} 表行下标
         */
        tableRowClassName({ rowIndex }) {
            if (rowIndex < 4) {
                return "background:orange";
            }
            else if (rowIndex >= 4 && rowIndex <= 31) {
                return "background:green;color:white;";
            }
            else {
                return "background:red;";
            }
        },
        updateDOM() {
            this.axios.get("/api/allteam")
                .then(res => {
                    this.team = res.data;
                    console.log(this.team);
                });
        }
    },
    components: { GoogleRecaptcha }
}
</script>

<style scoped lang="css">
@media screen and (max-width:768px) {
    div .allteam {
        width: 100%;
    }

    div #googlebox {
        z-index:999;
        position: absolute;
        left: 10%;
        top: 40%;
    }
}

div {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.container {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    top: 0;
    z-index: 999;
    display: flex;
    background-color: white;
    position: sticky;
    align-items: center;
    flex-direction: row;
    justify-content: left;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
}

.container img {
    width: 50px;
    height: 50px;
}

.container a {
    text-decoration: none;
    color: #030303;
    font-size: 16px;
    margin: 0 25px;
}

.allteam {
    width: 80%;
    border: 1px solid gray;
    margin: 0 auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.allteam h2 {
    text-align: center;
    color: skyblue;
}

#googlebox {
    position: absolute;
    left: 40%;
    top: 40%;
}
</style>

<template>
  <footer>
      <p>本网站由ASG杯赛事组自行研发@1.0.0 version</p>
  </footer>
</template>

<script>
export default {
   name: 'Fbanner' 
}
</script>

<style scoped lang="css">
  footer{
    width:100%;
    height:70px;
    background-color: rgb(17,0,158);
  }
  footer p{
     color:rgb(197,176,255);
     font-size: 14px;
     line-height: 70px;
     text-align: center;
  }
</style>

<template>
    <!-- 内容区 -->
    <div id="box">
        <div class="box">
            <div class="imgBx">
                <img src="https://i.hd-r.cn/0a0f4393e7eb06c155c53dc4d01f2440.png">
            </div>
            <div class="content">
                <h2>第一届冠军TPX<br>
                    <span>求生者：乐怡、七七、珍珍、捣乱</span><br>
                    <span>监管者：小轩</span>
                </h2>
            </div>
        </div>
        <div class="box">
            <div class="imgBx">
                <img src="https://i.hd-r.cn/706c3a68d05e45f2a604dd92d6dce7da.png">
            </div>
            <div class="content">
                <h2>第二届冠军Lyon<br>
                    <span>求生者：鹗冕、俩俩、小贼、柴瞳、SKylarK</span><br>
                    <span>监管者：博弈、支南</span>
                </h2>
            </div>
        </div>
        <div class="box">
            <div class="imgBx">
                <img src="https://i.hd-r.cn/40a85c9617252fd84541f6178d91d3d4.png">
            </div>
            <div class="content">
                <h2>第三届冠军Tpof<br>
                    <span>求生者：月海、暗夜、原妹、茶色、柠檬、小獭</span><br>
                    <span>监管者：鱼生</span>
                </h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Chamimg'
}
</script>

<style scoped lang="css">
* {
    box-sizing: border-box;
}

#box {
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    min-height: 100vh;
    background:#030303;
    position: relative;
}
#box .box{
    position:relative;
    width:300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:40px;
    background: rgb(255, 222, 90);
    transition:0.5s;
}
#box .box:hover{
    height: 500px;
}
#box .box .imgBx{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    padding:10px;
    box-sizing: border-box;
}
#box .box .imgBx img{
    max-width:100%;
    opacity:0.1;
    transition:0.5s;
}
#box .box:hover .imgBx img{
    opacity: 1;
}
#box .box::before{
    content:'';
    position: absolute;
    left:-2px;
    top:-2px;
    right:-2px;
    bottom: -2px;
    background:#bbb;
    filter:blur(60px);
}
#box .box .content{
    position:absolute;
    bottom:0;
    left:10px;
    right:10px;
    bottom: 10px;
    height: 190px;
    background: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;
    transition:0.5s;
}
#box .box:hover .content{
    opacity:1;
}
#box .box .content h2{
    font-size: 20px;
    color:rgb(197,176,255);
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 1px;
}
#box .box .content h2 span{
    font-size: 14px;
    color:#fff;
    font-weight: 200;
    letter-spacing: 2px;
}
</style>

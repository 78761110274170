<template>
    <header>
        <div class="container">
            <img src="https://i.hd-r.cn/d83dab6fd67e9a107f0fc27c3874ce7c.png">
            <h1><a @click="toggleLogin">
            <i v-if="userIcon" class="el-icon-user-solid"></i>{{ userInfo }}</a></h1>
            <ul ref="ulBox">
                <li><router-link to="/" @click="open">关于ASG</router-link></li>
                <li><router-link to="/enroll">点击报名</router-link></li>
                <li><router-link to="/championship">历届冠军</router-link></li>
                <li><router-link to="/" @click="open">实时信息</router-link></li>
            </ul>
            <div class="line-box" ref="iconBox" @click="handerIcon">
                <span class="line line-1"></span>
                <span class="line line-2"></span>
                <span class="line line-3"></span>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'Banner',
    data() {
        return {
            userInfo: '',
            userIcon: true,
        }
    },
    methods: {
        open() {
            this.$alert('功能尚未开发', '警示', {
                confirmButtonText: '确定',
            });
        },
        handerIcon() {
            this.$refs.ulBox.classList.toggle('active');
            this.$refs.iconBox.classList.toggle('close');
        },
        toggleLogin(){
            if(localStorage.getItem('userName')){
               this.$message.warning('账号已退出登录！');
               localStorage.removeItem('userName');
               this.$router.push('/login')
            }else{
                this.$router.push('/login')
            }
        }
    },
    mounted() {
        const userName = localStorage.getItem('userName')
        if (userName) {
            this.userInfo = '欢迎！'+ userName
            this.userIcon = false
        } else {
            this.userInfo = '登录'
        }
    }
}
</script>

<style scoped lang="css">
* {
    box-sizing: border-box;
}

/* 响应式 */
@media screen and (max-width:1200px) {
    div header .container {
        width: 100%;
        padding: 0 40px;
    }

    div header .container img {
        display: none;
    }

    div header .container h1 {
        font-weight: 500;
        font-size: 1rem;
    }

    div header .container ul {
        position: fixed;
        top: 64px;
        width: 100%;
        height: calc(100vh - 64px);
        flex-direction: column;
        justify-content: space-between;
        padding: 50px 0 120px;
        background: white;
        gap: 0;
        pointer-events: none;
        right: -1400px;
        transition: .4s;
    }

    div header .container ul.active {
        right: 0px;
        pointer-events: auto;
    }

    div header .container ul li {
        flex: 1;
    }

    div header .container ul li a {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    div header .container .line-box {
        display: block;
        width: 26px;
        height: 20px;
        cursor: pointer;
    }

    div header .container .line-box .line {
        width: 100%;
        height: 2px;
        background-color:rgb(135,149,254);
        display: block;
        transition: .2s;
    }

    header .container .line-box .line-2,
    header .container .line-box .line-3 {
        margin-top: 7px;
    }

    header .container .line-box.close .line-2 {
        opacity: 0;
    }

    header .container .line-box.close .line-1 {
        transform: translateY(9px) rotate(45deg);
    }

    header .container .line-box.close .line-3 {
        transform: translateY(-9px) rotate(-45deg);
    }
}

/* PC端 */
header {
    width: 100%;
    height: 64px;
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: white;
    box-shadow: 2px 0 12px 0 rgba(0, 0, 0, 0.3);
}

header .container {
    position: relative;
    width: 80%;
    height: 100%;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

header .container h1 {
    font-weight: 500;
    font-size: 1.2rem;
}

/* logo */
header .container img {
    left:-60px;
    width: 60px;
    height: 60px;
    position:absolute!important;
}

header .container h1 a {
    color: rgb(135,149,254);
    text-decoration: none;
    cursor: pointer;
}
header .container h1 a:hover {
    color: rgb(197,176,255);
}


header .container ul {
    display: flex;
    gap: 28px;
    list-style: none;
}

header .container ul li a {
    color: #030303;
    font-size: 18px;
    text-decoration: none;
    position: relative;
    line-height: 64px;
    display: block;
}


header .container ul li a::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color:rgb(74, 66, 229);
    left: 0;
    bottom: 0;
    transform: scale(0);
    transition: .4s;
}

header .container ul li a:hover::after {
    transform: scale(1);
}
header .container ul li a:hover{
    color: rgb(17,0,158);
}

header .container .line-box {
    display: none;
}</style>

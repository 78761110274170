import VueRouter from "vue-router";
// 引入组件
import HomeView from '@/views/HomeView'
import Enroll from '@/views/Enroll'
import Team from '@/views/Team'
import Championship from '@/views/Championship'
import Thanks from '@/views/Thanks'
import Login from '@/views/Login'


const router = new VueRouter({
    routes: [
        {
            path: '/',
            component: HomeView,
            meta: { title: 'ASG官方网站' },
        },
        {
            path: '/enroll',
            component: Enroll,
            meta: { title: '报名' },
        },
        {
            path:'/team',
            component: Team,
            meta: { title: '现报名上队伍' },
        },
        {
            path:'/championship',
            component: Championship,
            meta: { title: '历届冠军' },
        },
        {
            path: '/thanks',
            component: Thanks,
            meta: { title: '感谢' },
        },
        {
            path: '/login',
            component: Login,
            meta: { title: '登录' },
        }
    ]
});

export default router
<template>
    <div class="container">
            <router-link to="/">主页</router-link>
            <router-link to="/team">查询报名队伍</router-link>
    </div>
</template>

<script>
export default {
   name: 'SearchBan', 
}
</script>

<style scoped lang="css">
.container {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    top: 0;
    z-index: 999;
    display: flex;
    background-color: white;
    position: sticky;
    align-items: center;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
}

.container a {
    text-decoration: none;
    color: #030303;
    font-size: 16px;
    margin: 0 25px;
}
.container a:hover{
    color:rgb(74, 66, 229);
}
</style>

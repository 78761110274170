<template>
  <div class="bcg">
    <div class="container">
      <router-link to="/">主页</router-link>
      <a href="javascript:;">欢迎来到冠军殿堂!</a>
    </div>
    <!-- 下面部分 -->
    <div class="wrapper">
      <!-- 头部 -->
      <div class="header">
        <span>ASG冠军堂</span>
        <el-divider><i class="el-icon-medal-1" /></el-divider>
      </div>
     <!-- 内容区 -->
     <Chamimg />
    </div>
  </div>
</template>

<script>
import Chamimg from '../components/champion/Chamimg.vue'
export default {
  name: 'Championship',
  components:{ Chamimg }
}
</script>

<style scoped lang="css">
.bcg {
  width: 100vw;
  height: 120vh;
  background-color: rgb(74,66,229);
}

.container {
  width: 100%;
  height: 50px;
  display: flex;
  background-color: white;
  align-items: center;
  position: sticky;
  top:0;
  z-index: 999;
  box-shadow: 2px 0 12px 0 rgba(0, 0, 0, 0.3);
}

.container a {
  text-decoration: none;
  color: #030303;
  font-size: 18px;
  margin: 0 25px;
  
}

.wrapper .header {
  text-align: center;
}

.wrapper .header span {
  display: inline-block;
  margin: 7px 0;
  font-size: 28px;
  font-weight: bold;
  color: black;
  font-family: 'Segoe UI', Verdana, sans-serif;
}

</style>

<template>
    <div id="g-recaptcha"></div>
</template>

<script>
export default {
    data() {
        return {
            sitekey: "6LcdXUEmAAAAACHoMlbHRilEp6pTwoMctq-8P0g9"
        };
    },
    methods: {
        submit: function (token) {
            this.$emit('getToken', token)
        },
        loaded() {
            setTimeout(() => {
                window.grecaptcha.render("g-recaptcha", {
                    sitekey: this.sitekey,
                    callback: this.submit
                });
            }, 200);
        }
    },
    mounted() {
        this.loaded();
    }
}
</script>


<template>
    <div class="container">
        <div class="formbox">
            <div class="form-nonvisible" ref="form">
                <!-- 登录盒子：左边 -->
                <div class="login-form">
                    <h2>登录</h2>
                    <input type="text" placeholder="请输入用户名" v-model="login_userName">
                    <div id="passwordbox">
                        <i :class="login_iflock" @click="toggleLogin"></i>
                        <input ref="login_pwinput" type="password" placeholder="请输入密码" v-model="login_password">
                    </div>
                    <div class="btnclass" @click="login">登录</div>
                    <div class="footer">
                        <router-link to="/">返回首页</router-link>
                        <a class="register" @click="toRegister">没有账号?点击注册</a>
                    </div>
                </div>
                <!-- 注册盒子：右边 -->
                <div class="register-form">
                    <h2>注册</h2>
                    <input type="text" placeholder="请输入用户名" v-model="userName" @blur="testName" maxlength="6">
                    <div id="passwordbox">
                        <i :class="register_iflock" @click="toggleRegister"></i>
                        <input ref="register_pwinput" type="password" placeholder="请输入密码" v-model="password"
                            @blur="testPassword">
                    </div>
                    <input type="text" placeholder="请输入邮箱" v-model="eMail" @blur="testEmail">
                    <div class="getCode">
                        <input type="text" placeholder="请输入验证码" v-model="emailCode"><span @click="testHuman">验证码</span>
                    </div>
                    <div class="btnclass" @click="register">注册</div>
                    <div class="footer">
                        <a @click="toLogin"><i class="el-icon-back"></i>返回登录</a>
                    </div>
                </div>

            </div>
        </div>
        <div id="googlebox">
            <GoogleRecaptcha v-if="showGoogle" @getToken="getToken" />
        </div>
    </div>
</template>

<script>
import GoogleRecaptcha from '../components/common/GoogleRecaptcha.vue';

export default {
    name: "Login",
    data() {
        return {
            // 登录：
            login_userName: "",
            login_password: "",
            //    注册
            userName: "",
            password: "",
            eMail: "",
            emailCode: "",
            //   正则
            name_reg: /^[A-Za-z]{3,6}$/,
            email_reg: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
            password_reg: /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/,
            // 密钥
            showGoogle: false,
            captoken: '',
            // 显示隐藏密码
            login_password_show: false,
            register_password_show: false,
            login_iflock: 'el-icon-lock',
            register_iflock: 'el-icon-lock'
        };
    },
    comments: { GoogleRecaptcha },
    methods: {
        getValidateCode(value) {
            console.log(value);
        },
        toRegister() {
            this.$refs.form.style.left = "-100%";
        },
        toLogin() {
            this.$refs.form.style.left = "0";
        },
        // 获取邮件代码
        testHuman() {
            this.showGoogle = true
        },
        // 得值
        getToken(token) {
            this.captoken = token,
                this.showGoogle = false,
                this.getCode()
        },
        // 显示或隐藏密码
        toggleLogin() {
            if (!this.login_password_show) {
                this.login_iflock = 'el-icon-unlock',
                    this.$refs.login_pwinput.type = 'text',
                    this.login_password_show = true
            } else {
                this.login_iflock = 'el-icon-lock',
                    this.$refs.login_pwinput.type = 'password',
                    this.login_password_show = false
            }
        },
        toggleRegister() {
            if (!this.register_password_show) {
                this.register_iflock = 'el-icon-unlock',
                    this.$refs.register_pwinput.type = 'text',
                    this.register_password_show = true
            } else {
                this.register_iflock = 'el-icon-lock',
                    this.$refs.register_pwinput.type = 'password',
                    this.register_password_show = false
            }
        },
        getCode() {
            this.axios.post(`/api/enroll?captoken=${this.captoken}`, {
                userName: this.userName,
                password: this.password,
                eMail: this.eMail
            })
                .then(() => {
                    this.$message.success("发送邮件成功!");
                })
                .catch(err => {
                    this.$message.error("服务器繁忙" + err.description);
                });
        },
        testName() {
            if (!this.name_reg.test(this.userName)) {
                this.$message.error("用户名只能为英文,且长度为3-6字符!");
            }
        },
        testEmail() {
            if (!this.email_reg.test(this.eMail)) {
                this.$message.error("邮箱不合法！");
            }
        },
        testPassword() {
            if (!this.password_reg.test(this.password)) {
                this.$message.error("密码必包括大小写字母，1个数字和1个特殊字符");
            }
        },
        // 注册
        register() {
            if (this.userName === "" || this.password === "" || this.eMail === "" || this.emailCode === "") {
                this.$message.error("注册信息不全！");
            }
            else {
                this.axios.post("/api/okemail", {
                    email: this.eMail,
                    token: this.emailCode
                })
                    .then(() => {
                        this.$message.success("注册成功！");
                        this.$refs.form.style.left = "0";
                    })
                    .catch(() => {
                        this.$message.error("验证码错误！");
                    });
            }
        },
        // 登录
        login() {
            this.axios.post(`/api/login?UserName=${this.login_userName}&Password=${this.login_password}`)
                .then((res) => {
                    this.$message.success("登录成功!");
                    localStorage.setItem("userName", this.login_userName);
                    this.$router.push("/");
                    localStorage.setItem("token", res.data);
                })
                .catch(() => {
                    this.$message.error("账号或者密码错误");
                });
        }
    },
    components: { GoogleRecaptcha }
}
</script>

<style scoped lang="css">
/* 媒体查询 ,响应宽度*/
@media screen and (max-width:768px) {
    div .container .formbox {
        position: relative;
        height: 60vh;
        width: 100%;
        overflow: hidden;
    }

    div .container .formbox .form-nonvisible .login-form h2 {
        margin: 60px 0;
    }

    div .container #googlebox {
        z-index:999;
        position: absolute;
        left: 10%;
        top: 40%;
    }

}

/* 响应高度 */
@media screen and (max-height:700px) and (min-width:768px) {
    div .container .formbox {
        position: relative;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        height: 70vh;
        width: 50%;
        backdrop-filter: blur(15px);
        overflow: hidden;
    }

    div .container .formbox .form-nonvisible {
        position: absolute;
        /* 从0改成-100% */
        left: 0;
        height: 80vh;
        /* 定义盒子是两倍 */
        width: 200%;
        box-sizing: border-box;
        transition: .8s;
    }

    div .container .formbox .form-nonvisible .login-form {

        position: relative;
        float: left;
        height: 70vh;
        width: 50%;
        box-sizing: border-box;
        text-align: center;
    }

    div .container .formbox .form-nonvisible .register-form {
        position: relative;
        float: left;
        height: 80vh;
        width: 50%;
        box-sizing: border-box;
        text-align: center;
    }
}

.container {
    height: 100vh;
    background: linear-gradient(to right, pink, skyblue);
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .formbox {
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    height: 60vh;
    width: 30%;
    backdrop-filter: blur(15px);
    overflow: hidden;
}

.container .formbox .form-nonvisible {
    position: absolute;
    /* 从0改成-100% */
    left: 0;
    height: 60vh;
    /* 定义盒子是两倍 */
    width: 200%;
    box-sizing: border-box;
    transition: .8s;
}

/* 登录盒子 */
.container .formbox .form-nonvisible .login-form {
    position: relative;
    float: left;
    height: 100%;
    width: 50%;
    box-sizing: border-box;
    text-align: center;
}

.container .formbox .form-nonvisible .login-form #passwordbox {
    position: relative;
}

.container .formbox .form-nonvisible .login-form #passwordbox i {
    position: absolute;
    right: 20%;
    top: 25px;
}

.container .formbox .form-nonvisible .register-form #passwordbox {
    position: relative;
}

.container .formbox .form-nonvisible .register-form #passwordbox i {
    position: absolute;
    right: 20%;
    top: 20px;
}

.container .formbox .form-nonvisible .login-form h2 {
    margin: 50px 0;
}

.container .formbox .form-nonvisible .login-form input {
    background-color: transparent;
    border: none;
    outline: none;
    width: 60%;
    height: 25px;
    border-bottom: 2px solid rgb(147, 140, 140);
    margin: 20px 0;
}

/* 按钮 */
.container .formbox .form-nonvisible .login-form .btnclass {
    color: white;
    margin: 20px auto;
    background: linear-gradient(to right, rgb(50, 102, 206), rgb(92, 161, 225), rgb(50, 100, 201));
    border-radius: 5px;
    width: 60%;
    height: 35px;
    line-height: 35px;
    cursor: pointer
}

.container .formbox .form-nonvisible .login-form .btnclass:hover {
    background: rgb(50, 102, 206);
}

/* 底部 */
.container .formbox .form-nonvisible .login-form .footer {
    width: 100%;
    position: absolute;
    bottom: 0;
}

div .container .formbox .form-nonvisible .login-form .footer a {
    text-decoration: none;
    color: black;
    float: left;
    cursor: pointer;
}

div .container .formbox .form-nonvisible .login-form .footer .register {
    color: purple;
    position: absolute;
    right: 0;
    text-decoration: underline;
}

/* 注册盒子 */
.container .formbox .form-nonvisible .register-form {
    float: right;
    height: 100%;
    width: 50%;
    text-align: center;
    box-sizing: border-box;
}

.container .formbox .form-nonvisible .register-form h2 {
    margin: 50px 0;
}

.container .formbox .form-nonvisible .register-form input {
    background-color: transparent;
    border: none;
    outline: none;
    width: 60%;
    height: 25px;
    border-bottom: 2px solid rgb(147, 140, 140);
    margin: 10px 0;
}

.container .formbox .form-nonvisible .register-form .getCode {
    text-align: center;
    width: 60%;
    margin: 0 auto;
}

.container .formbox .form-nonvisible .register-form .getCode span {
    box-sizing: border-box;
    display: inline-block;
    border: 1px solid black;
    background-color: gray;
    width: 30%;
    border-radius: 2px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
}

.container .formbox .form-nonvisible .register-form .getCode span:hover {
    background-color: yellow;
}

.container .formbox .form-nonvisible .register-form .getCode input {
    width: 50%;
    margin-right: 20%;
}

/* 按钮 */
.container .formbox .form-nonvisible .register-form .btnclass {
    margin: 20px auto;
    background: linear-gradient(to right, rgb(50, 102, 206), rgb(92, 161, 225), rgb(50, 100, 201));
    color: white;
    border-radius: 5px;
    width: 60%;
    height: 35px;
    line-height: 35px;
    cursor: pointer
}

.container .formbox .form-nonvisible .register-form .btnclass:hover {
    background: rgb(50, 102, 206);
}

/* 底部 */
.container .formbox .form-nonvisible .register-form .footer {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.container .formbox .form-nonvisible .register-form .footer a {
    text-decoration: underline;
    color: purple;
    float: left;
    cursor: pointer;
}

/* google */
#googlebox {
    position: absolute;
    left: 40%;
    top: 40%;
}
</style>

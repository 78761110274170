<template>
    <div class="container-wrapper">
        <SearchBan />
        <div class="formbox">
            <h1><i class="el-icon-edit"></i>第四届ASG杯报名信息</h1>
            <ul class="titlebox">
                <li>
                    <h3>战队名:</h3>
                    <el-input @blur="checkSameName" @focus="resetName" placeholder="战队名不超过10字符" v-model="form.team_name"
                        maxlength="10" show-word-limit></el-input>
                </li>
                <li class="warning-msg" v-if="isShow">
                    <p ref="pNode"><i :class="icon"></i>{{ msg }}</p>
                </li>
                <li>
                    <h3>队长联系方式:</h3>
                    <el-input placeholder="请输入QQ号" v-model="form.team_tel"></el-input>
                </li>
                <li>
                    <h3>密码设置</h3>
                    <el-input placeholder="修改战队信息密码" v-model="form.team_password"></el-input>
                </li>
            </ul>
            <el-divider>选手一信息</el-divider>
            <ul class="colbox">
                <li>
                    <h3>昵称:</h3>
                    <el-input v-model="form.role_get[0].role_name"></el-input>
                </li>
                <li>
                    <h3>第五人格ID:</h3>
                    <el-input v-model="form.role_get[0].role_id"></el-input>
                </li>
                <li>
                    <h3>阵营</h3>
                    <el-radio-group v-model="form.role_get[0].role_lin">
                        <el-radio label="监管者"></el-radio>
                        <el-radio label="求生者"></el-radio>
                    </el-radio-group>
                </li>
            </ul>
            <el-divider>选手二信息</el-divider>
            <ul class="colbox">
                <li>
                    <h3>昵称:</h3>
                    <el-input v-model="form.role_get[1].role_name"></el-input>
                </li>
                <li>
                    <h3>第五人格ID:</h3>
                    <el-input v-model="form.role_get[1].role_id"></el-input>
                </li>
                <li>
                    <h3>阵营</h3>
                    <el-radio-group v-model="form.role_get[1].role_lin">
                        <el-radio label="监管者"></el-radio>
                        <el-radio label="求生者"></el-radio>
                    </el-radio-group>
                </li>
            </ul>
            <el-divider>选手三信息</el-divider>
            <ul class="colbox">
                <li>
                    <h3>昵称:</h3>
                    <el-input v-model="form.role_get[2].team_name"></el-input>
                </li>
                <li>
                    <h3>第五人格ID:</h3>
                    <el-input v-model="form.role_get[2].role_id"></el-input>
                </li>
                <li>
                    <h3>阵营</h3>
                    <el-radio-group v-model="form.role_get[2].role_lin">
                        <el-radio label="监管者"></el-radio>
                        <el-radio label="求生者"></el-radio>
                    </el-radio-group>
                </li>
            </ul>
            <el-divider>选手四信息</el-divider>
            <ul class="colbox">
                <li>
                    <h3>昵称:</h3>
                    <el-input v-model="form.role_get[3].role_name"></el-input>
                </li>
                <li>
                    <h3>第五人格ID:</h3>
                    <el-input v-model="form.role_get[3].role_id"></el-input>
                </li>
                <li>
                    <h3>阵营</h3>
                    <el-radio-group v-model="form.role_get[3].role_lin">
                        <el-radio label="监管者"></el-radio>
                        <el-radio label="求生者"></el-radio>
                    </el-radio-group>
                </li>
            </ul>
            <el-divider>选手五信息</el-divider>
            <ul class="colbox">
                <li>
                    <h3>昵称:</h3>
                    <el-input v-model="form.role_get[4].role_name"></el-input>
                </li>
                <li>
                    <h3>第五人格ID:</h3>
                    <el-input v-model="form.role_get[4].role_id"></el-input>
                </li>
                <li>
                    <h3>阵营</h3>
                    <el-radio-group v-model="form.role_get[4].role_lin">
                        <el-radio label="监管者"></el-radio>
                        <el-radio label="求生者"></el-radio>
                    </el-radio-group>
                </li>
            </ul>
            <el-divider>选手六信息</el-divider>
            <ul class="colbox">
                <li>
                    <h3>昵称:</h3>
                    <el-input v-model="form.role_get[5].role_name"></el-input>
                </li>
                <li>
                    <h3>第五人格ID:</h3>
                    <el-input v-model="form.role_get[5].role_id"></el-input>
                </li>
                <li>
                    <h3>阵营</h3>
                    <el-radio-group v-model="form.role_get[5].role_lin">
                        <el-radio label="监管者"></el-radio>
                        <el-radio label="求生者"></el-radio>
                    </el-radio-group>
                </li>
            </ul>
            <el-divider>选手七信息</el-divider>
            <ul class="colbox">
                <li>
                    <h3>昵称:</h3>
                    <el-input v-model="form.role_get[6].role_name"></el-input>
                </li>
                <li>
                    <h3>第五人格ID:</h3>
                    <el-input v-model="form.role_get[6].role_id"></el-input>
                </li>
                <li>
                    <h3>阵营</h3>
                    <el-radio-group v-model="form.role_get[6].role_lin">
                        <el-radio label="监管者"></el-radio>
                        <el-radio label="求生者"></el-radio>
                    </el-radio-group>
                </li>
            </ul>
            <el-divider><i class="el-icon-edit-outline"></i></el-divider>
            <div class="button_wrap">
                <el-button type="primary" style="width:100%" @click="submit">点击报名</el-button>
            </div>
            <img src="https://i.hd-r.cn/cfa66a20dbf3af322ce24ee3c6566ad9.jpg">
        </div>
        <!-- 协议 -->
        <div class="checkedbox" v-if="confirm_show">
            <div class="information">
                <p>选手须知比赛协议内容。</p>
                <p>选手一旦报名不能更换游戏账号。</p>
                <p>由于选手不可抗拒的原因导致无法参加比赛的，作弃权处理。</p>
                <p>选手在游戏中出现网卡等现象，由选手自行承担责任。</p>
                <p>若比赛导致选手心态不好，由选手自行承担。</p>
                <p>bp超时导致无法选用想要的角色，由选手自行承担。</p>
                <p>若进行辱骂、代打、假赛等行为，ASG杯有权对其进行禁赛处理。</p>
            </div>
            <el-checkbox v-model="checked">我同意ASG杯比赛协议</el-checkbox>
            <el-button type="primary" size="mini" @click="agree" style="margin:0 10px;">同意</el-button>
        </div>
    </div>
</template>

<script>
import SearchBan from '../components/common/SearchBan.vue';
export default {
    name: 'Enroll',
    components: { SearchBan },
    data() {
        return {
            form: {
                team_name: '',
                team_tel: '',
                team_password: '',
                role_get: [
                    { role_name: '', role_id: '', role_lin: '' },
                    { role_name: '', role_id: '', role_lin: '' },
                    { role_name: '', role_id: '', role_lin: '' },
                    { role_name: '', role_id: '', role_lin: '' },
                    { role_name: '', role_id: '', role_lin: '' },
                    { role_name: '', role_id: '', role_lin: '' },
                    { role_name: '', role_id: '', role_lin: '' },
                ],
            },
            isShow: false,
            icon: 'el-icon-loading',
            msg: '检测中......',
            confirm_show: false,
            checked: false,
        }
    },
    mounted() {
        this.$message.warning('请真实填写内容，否则拉黑');
    },
    methods: {
        submit() {
            const valid = this.valid((this.form))
            if (valid) {
                this.confirm_show = true
            } else {
                this.$message.error('表单信息不全！')
            }
        },
        valid(form) {
            console.log('form', this.rolevalid(this.form.role_get))
            if (form.team_name === '' || form.team_tel === '' || form.team_password === '' || this.rolevalid(this.form.role_get)) {
                return false;
            } else {
                return true;
            }
        },
        rolevalid(role) {
            for (let i = 0; i < role.length; i++) {
                if (role[i].role_name === '' || role[i].role_id === '' || role[i].role_lin === '') {
                    return true;
                }
                return false;
            }
        },
        /**
         * @Author FYN
         * @function 失去焦点检测队伍是否重名
         */
        checkSameName() {
            this.isShow = true;
            this.axios.post(`/api/chongname?name=${this.form.team_name}`)
                .then(res => {
                    console.log(res);
                    setTimeout(() => {
                        this.msg = '该战队名可用',
                            this.icon = 'el-icon-check'
                        this.$refs.pNode.style.color = 'green'
                    }, 2000)
                })
                .catch(() => {
                    setTimeout(() => {
                        this.msg = '战队名重复！'
                        this.icon = 'el-icon-close'
                        this.$refs.pNode.style.color = 'red'
                        this.$message.error('战队名重复');
                    }, 2000)
                })
        },
        // 重置
        resetName() {
            this.isShow = false;
            this.msg = '检测中......',
                this.icon = 'el-icon-loading'
        },
        // 报名
        sendInformation() {
            this.axios.post('/api/updateform', {
                team_name: this.form.team_name,
                team_tel: this.form.team_tel,
                team_password: this.form.team_password,
                role_get: this.form.role_get
            })
                .then(res => {
                    console.log(res)
                    this.$message.success("报名表提交成功！");
                    this.$router.push({ path: '/thanks' });
                })
                .catch(err => {
                    this.$message.error(err + "服务器连接失败,请重试");
                })
        },
        agree() {
            if (this.checked) {
                this.sendInformation();
            } else {
                this.$message.error('请勾选我同意比赛协议!')
            }
        }

    }
}
</script>

<style scoped lang="css">
/* 响应式 */
@media screen and (max-width:768px) {
    .container-wrapper {
        position: relative;
    }

    .container-wrapper .formbox {
        width: 100%;
    }

    .container-wrapper .formbox .colbox li {
        float: none;
        width: 90%;
        margin: calc(10% / 2);
    }

    .container-wrapper .formbox .titlebox li {
        float: none;
        width: 90%;
        margin: calc(10% / 2);
    }

    .container-wrapper .formbox .titlebox .warning-msg {
        float: none;
        width: 90%;
        margin: 0 auto;
    }

    .container-wrapper .formbox .button_wrap {
        width: 90%;
        margin: 5px 5%;
    }

    .container-wrapper .checkedbox {
        position: absolute;
        top: 80%;
        height: 45vh;
        background-color: white;
        width: 100%;
    }
}

/* checkedbox */
.checkedbox {
    background-color: white;
    position: absolute;
    top: 100%;
    height: 50%;
    width: 50%;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.checkedbox .information {
    overflow-y: scroll;
    width: 90%;
    margin: 5% 5% 0;
    height: 35vh;
    border: 1px solid gray;
}

.el-checkbox {
    margin: 1% 5%;
}

/* 表单 */
.formbox {
    display: flex;
    flex-direction: column;
    width: 90%;
    border: 1px solid black;
    border-top:none;
    border-bottom: none;
}

.formbox img {
    display: block;
    width: 100%;
    height: auto;
}

.formbox h1 {
    text-align: center;
    margin: 10px 0;
}


/* titlebox */
.formbox .titlebox {
    width: 100%;
    list-style: none;
}

.formbox .titlebox li {
    float: left;
    width: 20%;
    margin: 5px calc(30% / 8);
}

.formbox .titlebox .warning-msg {
    float: left;
    width: 10%;
    margin: 25px 0;
}

.formbox .colbox {
    width: 100%;
    list-style: none;
}

.formbox .colbox li {
    float: left;
    width: 20%;
    margin: 5px calc(40% / 6);
}

.formbox .button_wrap {
    text-align: center;
    margin: 10px auto;
    width: 50%;
}

.container-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}</style>

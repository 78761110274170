<template>
  <div class="clearfix">
     <Banner />
     <div class="swiper-wrap">
       <SwiperDemo />
     </div>
     <Ad />
     <div class="container">
      <el-empty description="功能尚未开发！"></el-empty>
     </div>
     <Fbanner />
  </div>
</template>

<script>
import Banner from '@/components/homeview/Banner.vue';
import SwiperDemo from '../components/common/SwiperDemo.vue';
import Ad from '../components/homeview/Ad.vue';
import Fbanner from '../components/homeview/Fbanner.vue'
export default {
  name: 'HomeViews',
  components: { Banner, SwiperDemo, Ad, Fbanner  }
}
</script>

<style scoped lang="css">
  .container{
    clear:both;
  }
  .clearfix{
    background-color: #ddd;
  }
</style>

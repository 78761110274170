<template>
    <div class="swiper-demo">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div v-for="item in banner" :key="item.id" class="swiper-slide">
                   <img :src="item.image">
                </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
    </div>
</template>
<!--       -->
<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
    name: 'SwiperDemo',
    data() {
        return {
            banner: [
                {
                    image: 'https://i.hd-r.cn/ae67feff8ec4be0dec754d25ca50c61d.jpg',
                    id: 0
                },
                {
                    image: 'https://i.hd-r.cn/e4014658054a619d2cc8e6b892b02ec2.png',
                    id: 1
                },
                {
                    image: 'https://i.hd-r.cn/ab950263ea5caa4f04e9e4b8f40fc7b1.png',
                    id: 2
                },
                {
                    image: 'https://i.hd-r.cn/780bdb4c1bcd4cefdef4e23a13906cbb.png',
                    id: 3
                },
                {
                    image: 'https://i.hd-r.cn/4e1bb5d896225cd5f02a43e04346fa98.png',
                    id: 4
                }

            ],
        }
    },
    mounted() {
        new Swiper('.swiper-container', {
            autoplay:true,
            loop: true, // 循环模式选项

            // 如果需要分页器
            pagination: {
                el: '.swiper-pagination',
            },

            // 如果需要前进后退按钮
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            // 如果需要滚动条
            scrollbar: {
                el: '.swiper-scrollbar',
            },
        })
        // console.log(Swiper)
    }
}
</script>

<style scoped lang="css">
@media screen and (max-width:768px) {
    .swiper-demo .swiper-container {
        width:100%;
        height: calc(100vw / 16 * 5);
    }
}

.swiper-container {
    width: 80%;
    height: calc(80vw / 16 * 5);
    margin: 10px auto;
}
.swiper-demo .swiper-container .swiper-wrapper .swiper-slide img{
   width:100%;
   height:100%;
}
</style>
